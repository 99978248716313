/* eslint-disable vue/no-unused-components */
<template>
  <div class="login_page_wrpr">
    <div class="content_wrpr d-flex align-items-center px-0">
      <div class="row w-100 mx-0">
        <div class="col-xl-5 col-lg-6 col-md-7 col-sm-9 mx-auto">
          <div class="auth_form text-left py-5 px-4 px-sm-5">
            <div class="brand-logo">
              <img src="../../assets/imgs/svg/logo_dark.svg" alt="logo" />
            </div>
            <h4>Hello! Let's Get Started</h4>
            <h6 class="f-300">Sign in to continue.</h6>
            <form class="pt-3" ref="auth_form" @submit.prevent novalidate>
              <EmailField v-model="user.email" label="Email" placeholder="Please input a valid email address"
                :v="$v.user.email" />

              <PasswordField v-model="user.password" label="Password" placeholder="Password" :v="$v.user.password" />

              <span class="err_msg" v-if="err_msg">
                {{ err_msg }}
              </span>

              <button type="submit" class="primary_btn_eva btn btn-block auth-form-btn mt-4" @click="login"
                :disabled="useButtonState">
                SIGN IN
                <BtnLoader v-if="loading" />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthServices from "@/services/AuthServices";
import InputText from "../../components/FormComponents/InputField.vue";
import EmailField from "../../components/FormComponents/EmailField.vue";
import PasswordField from "../../components/FormComponents/PasswordField.vue";
// import Loader from "../../components/Loader.vue";
import BtnLoader from "../../components/BtnLoader.vue";
import { email, required } from "vuelidate/lib/validators";
import Config from "@/Config";
import router from "@/router";
// import microsoftTeams from '@microsoft/teams-js'

export default {
  name: "LoginView",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    InputText,
    EmailField,
    PasswordField,
    BtnLoader,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loading: false,
      err_msg: "",
    };
  },
  validations: {
    user: {
      email: { required, email },
      password: { required },
    },
  },
  watch: {
    'user.password': function () {
      if (this.err_msg) this.err_msg = ''

    },
    'user.email': function () {
      if (this.err_msg) this.err_msg = ''
    }
  },
  methods: {
    resetErr() {
    },
    async login() {
      this.$v.user.$touch();
      this.loading = true;

      if (this.$v.$invalid) {
        this.loading = false;
      } else {
        this.loading = true;

        const credentials = {
          email: this.user.email,
          password: this.user.password,
          client_id: Config.apiGateWay.CLIENT_ID,
          client_secret: Config.apiGateWay.CLIENT_SECRET,
        };

        const response = await AuthServices.login(credentials);
        // this.msg = response.msg;
        if (response.status) {
          const token = response.data.token;
          const user = response.data.user;
          const reporting_people = response.data.reporting_persons
          const user_role_name = response.data.user_role_name;
          this.$store.dispatch("login", { token, user, user_role_name, reporting_people });
          const permissions = await AuthServices.getPermissions({user_role_id: response.data.user[0].user_role,user_id: response.data.user[0].id});
          if (permissions.status) {
            const AllPermissions = permissions.data
            this.$store.dispatch("permissions", { AllPermissions })
            let role = this.$store.getters.getUserRoleName.replace(/ /g, "_")
            if(user[0].is_login){
              router.push({ name: 'dashboard', params: { role } });
            }else{
              router.push({ name: 'change-password', params: { role } });
            }

            // let pop = await this.$msal.ssoSilent();
            //   let token = await this.$msal.acquireTokenPopup({
            //     account: pop.account,
            //     scopes: ["User.Read"]
            //   })
              // this.$store.dispatch("setGraphApiToken", { token });
          } else {
            this.loading = false;
            this.err_msg = response.message;
          }
          // console.log(permissions, 'permissions');
          // console.log(response, "viewpage");

        } else {
          this.loading = false;
          // console.log(response, "error");
          this.err_msg = response.message;
        }
      }
    }
  },
  computed: {
    // accounts() {
    //   return this.$msal.getAllAccounts()
    // },
    useButtonState() {
      // console.log(this.user.email);
      let user = this.user;
      let disabled = true;
      for (let prop in user) {
        if (!user[prop]) {
          disabled = true;
          break;
        }
        disabled = false;
      }
      return disabled;
    },
  },
};
</script>

// Users module
// import axios from "axios";

const state = {
  allPositions: [],
  positionbyId: {},
  allOpenings: [],
  openingById: {},
  allApplications: [],
  applciationById: {},
};

const getters = {
  getPositions: (state) => {
    return state.allPositions;
  },
  positionById: (state) => {
    return state.positionbyId;
  },
  getOpenings: (state) => {
    return state.allOpenings;
  },
  openingById: (state) => {
    return state.openingById;
  },
  getApplciations: (state) => {
    return state.allApplications;
  },
  applciationById: (state) => {
    return state.applciationById;
  },
};

const actions = {
  getPositions: ({ commit }, positions) => {
    commit("SetPositions", positions);
  },
  createPosition: ({ commit }, positions) => {
    commit("SetPositions", positions);
  },
  deletePosition: ({ commit }, positions) => {
    commit("SetPositions", positions);
  },
  getPositionById: ({ commit }, positions) => {
    // console.log("test", positions);
    commit("SetPositionbyId", positions);
  },
  editPosition: ({ commit }, positions) => {
    commit("SetPositionbyId", positions);
  },
  // opening
  getOpenings: ({ commit }, openings) => {
    commit("SetOpenings", openings);
  },
  createOpening: ({ commit }, openings) => {
    commit("SetOpenings", openings);
  },
  deletOpening: ({ commit }, openings) => {
    commit("Setopenings", openings);
  },
  getOpeningById: ({ commit }, openings) => {
    // console.log("test", openings);
    commit("SetOpeningbyId", openings);
  },
  editOpening: ({ commit }, openings) => {
    commit("SetOpeningbyId", openings);
  },
  getApplications: ({ commit }, applications) => {
    commit("SetApplications", applications);
  },
  getApplciationsById: ({ commit }, applications) => {
    commit("SetApplicationsById", applications);
  },
  deleteApplication: ({ commit }, applications) => {
    commit("SetApplications", applications);
  },
};

const mutations = {
  // login: (state, payload) => (state.user = payload.data),
  SetPositions: (state, payload) => {
    // console.log(payload, "payload");
    state.allPositions = payload;
  },

  SetPositionbyId: (state, payload) => {
    // console.log(payload, "payload");
    state.positionbyId = payload;
  },

  SetOpenings: (state, payload) => {
    // console.log(payload);
    state.getOpenings = payload;
  },

  SetOpeningbyId: (state, payload) => {
    // console.log(payload);
    state.openingById = payload;
  },

  SetApplications: (state, payload) => {
    // console.log(payload);
    state.allApplications = payload;
  },

  SetApplicationsById: (state, payload) => {
    // console.log(payload);
    state.applciationById = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};


import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/pages/LoginView.vue";
import BlankPage from '../views/pages/BlankPage.vue'
import EvaLayout from "../layouts/EvaLayout.vue";
import EvaWrapper from '../layouts/EvaWrapper.vue'
import store from "../store/index";

Vue.use(VueRouter);

const redirectToHomeOnLoggedIn = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    // console.log(store.getters,'getters');
  } else{
    // console.log('dashboard')
    next(`/${store.getters.getUserRoleName.replace(/ /g,"_")}/dashboard`);
  }
};

const redirectToLoginOnLoggedOut = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    // console.log('next')
    next();
  } else {
    // console.log('next-/')
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "LoginView",
    component: LoginView,
    meta: {
      authorize: [],
      title:'Login'
    },
    beforeEnter: redirectToHomeOnLoggedIn,
    // beforeRouteUpdate: redirectToHomeOnLoggedIn,
  },
  {
    path: `/:role`,
    name: "admin",
    redirect: "/dashboard",
    component: EvaWrapper,
    children: [
      {
        path: '/',
        name: 'profile-main',
        component: EvaLayout,
        children:[
          {
            path: "profile",
            name: "profile",
            meta: {
              title: 'Profile'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/ProfileView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
            beforeRouteUpdate: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'dashboard-main',
        component: EvaLayout,
        children:[
          {
            path: "dashboard",
            name: "dashboard",
            meta: {
              title: 'Dashboard'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/DashBoard.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
            beforeRouteUpdate: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'blog-main',
        component: EvaLayout,
        children:[
          {
            path: 'blog',
            name: "blog",
            meta: {
              title: 'Blog'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/BlogView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'case-study-main',
        component: EvaLayout,
        children:[
          {
            path: 'case-study',
            name: "case-study",
            meta: {
              title: 'Case Study'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CaseStudyView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'asset-management',
        component: EvaLayout,
        children:[
          {
            path: "assets",
            name: "assets",
            meta: {
              title: 'Assets'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/AssetsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'leads',
        component: EvaLayout,
        children:[
          {
            path: "estimate-list",
            name: "estimate-list",
            meta: {
              leads: true,
              title: 'Estimate'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/EstimateListView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      }, 
      {
        path: '/',
        name: 'teams',
        component: EvaLayout,
        children:[
          {
            path: "users",
            name:'users',
            meta: {
              title: 'Users'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/UsersView.vue"
              ),
              
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "users/:name",
            name: "users-view",
            meta: {
              title: 'Users View'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/UserDetailsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "users-add",
            name: "users-add",
            meta: {
              title: 'Users add'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/AddUserDetails.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "user-roles",
            name: "user-roles",
            meta: {
              title: 'User Roles'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/UserRoleView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "designations",
            name: "designations",
            meta: {
              title: 'Designations'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/DesignationView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "user-insights",
            name: "user-insights",
            meta: {
              title: 'User Insights'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/UserInsights.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'leave',
        component: EvaLayout,
        children:[
          {
            path: "holidays",
            name: "holidays",
            meta: {
              title: 'Holidays'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/HolidayView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-types",
            name: "leave-types",
            meta: {
              title: 'Leave Types'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveTypeView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "calendar",
            name: "calendar",
            meta: {
              title: 'Calendar'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CalendarView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-request",
            name: "leave-request",
            meta: {
              title: 'Leave Request'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveRequestView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-allocation",
            name: "leave-allocation",
            meta: {
              title: 'Leave Allocation'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveAllocationView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-reports",
            name: "leave-reports",
            meta: {
              title: 'Leave Reports'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/LeaveReportView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "leave-reports/:name",
            name: "leave-reports-view",
            meta: {
              title: 'Leave Reports View'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/LeaveReportsDetailsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'cms',
        component: EvaLayout,
        children:[
          {
            path: "services",
            name: "services",
            meta: {
              cms: true,
              title: 'Services'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/servicesListView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "testimonials",
            name: "testimonials",
            meta: {
              cms: true,
              title: 'Testimonials'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/TestimonialView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "portfolios",
            name: "portfolios",
            meta: {
              cms: true,
              title: 'Portfolios'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CMS/PortfolioView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "portfolio-categories",
            name: "portfolio-categories",
            meta: {
              cms: true,
              title:"Portfolio Categories"
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/PortfolioCategories.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "clients",
            name: "clients",
            meta: {
              career: true,
              title: 'Clients'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/ClientsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'careers',
        component: EvaLayout,
        children:[
          {
            path: "career-positions",
            name: "career-positions",
            meta: {
              career: true,
              title: 'Career Positions'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/Admin/CareerPositionView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "career-openings",
            name: "career-openings",
            meta: {
              career: true,
              title: "Career Openings"
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CareerOpeningView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
          {
            path: "career-applications",
            name: "career-applications",
            meta: {
              career: true,
              title:'Career Applications'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/CareerApplicationsView.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'error',
        component: EvaLayout,
        children:[
          {
            path: "404Error",
            name: "404Error",
            meta: {
              career: true,
              title: '404 Error'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/404View.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
      {
        path: '/',
        name: 'change-password',
        component: EvaWrapper,
        children:[
          {
            path: "change-password",
            name: "change-password",
            meta: {
              career: true,
              title: 'change-password'
            },
            component: () =>
              import(
                /* webpackChunkName: "about" */ "../views/pages/ChangePassword.vue"
              ),
            beforeEnter: redirectToLoginOnLoggedOut,
          },
        ]
      },
    ],
  },
  // {
  //   path: "*",
  //   component: EvaLayout,
  //   redirect:`/${store.getters.getUserRoleName}/404Error`
  // },
    {
    path: "/blank.html",
    name: "blankPage",
    component: BlankPage ,
    meta: {
      authorize: [],
      title:'Blank Page'
    },
    beforeEnter: redirectToHomeOnLoggedIn,
    // beforeRouteUpdate: redirectToHomeOnLoggedIn,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to, from,'to from');
    const title = to.meta.title
    if (title) {
      document.title = `${title} | Evalogical`
    }else document.title = 'Admin | Evalogical'
  let menu = store.getters.getPermissions.length ? store.getters.getPermissions?.filter(item => (item.menu == to.name)) : []
    if(menu.length || (to.name == '404Error') || !store.getters.isLoggedIn  || (to.path == '/') || (!store.getters.isInitialLogin && to.name == 'change-password') ){
      if(menu.length){
        store.dispatch('setEditAccess',menu[0].edit_access)
        store.dispatch('setDeleteAccess',menu[0].delete_access)
      } 
      next()
    }else{
      next(`/${store.getters.getUserRoleName.replace(/ /g,"_")}/404Error`);
    }
})

export default router;

const state = {
    employeeTimeSheet:[],
    employeeActivityReport:[],
}

const getters = {
    getEmployeeTimeSheet: (state) => {
        return state.employeeTimeSheet;
    },
    getEmployeeActivityReport: (state) => {
        return state.employeeActivityReport;
    }
}

const actions = {

    setEmployeeTimeSheet: ({commit},data) => {
        commit('SET_EMPLOYEE_TIME_SHEET',data);
    },
    setEmployeeActivityReport:  ({commit},data) => {
        commit('SET_EMPLOYEE_ACTIVITY_REPORT',data);
    },

}

const mutations = {

    SET_EMPLOYEE_TIME_SHEET: (state,payload) => {
        state.employeeTimeSheet = payload;
    },
    SET_EMPLOYEE_ACTIVITY_REPORT: (state,payload) => {
        state.employeeActivityReport = payload;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
};

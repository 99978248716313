import moment from "moment";

const state = {
    allLeaveAllocations:[],
    leaveAllocationByUserId:{},
    leaveAllocationByUserIdRaw:{},
    leaveRequestByUserId: [],
    leaveRequestById:{},
    leaveRequestEvents:[],
    exportLeaveReport: [],
    exportLeaveAllocationsHeaders:[],
    exportLeaveTypes:[],
    leaveReport:[],
    leaveReportDetails:[],
    leaveReportId:null,
    leaveReportName: ''
}

const getters = {
    getLeaveAllocations : (state) => {
        return state.allLeaveAllocations;
    },
    getLeaveAllocationsByUserId : (state) => {
        return state.leaveAllocationByUserId
    },
    getLeaveAllocationsByUserIdRaw : (state) => {
        return state.leaveAllocationByUserIdRaw
    },
    getLeaveRequestByUserId : (state) => {
        return state.leaveRequestByUserId
    },
    getLeaveRequestById : (state) => {
        return state.leaveRequestById
    },
    getLeaveRequestEvents : (state) => {
        return state.leaveRequestEvents
    },
    getExportLeaveAllocationsHeaders : (state) => {
        return state.exportLeaveAllocationsHeaders
    },
    getExportLeaveAllocations : (state) => {
        return state.exportLeaveAllocations
    },
    getLeaveReport : (state) => {
        return state.leaveReport
    },
    getExportLeaveReport : (state) => {
        return state.exportLeaveReport
    },
    getLeaveReportDetails : (state) => {
        return state.leaveReportDetails
    },
    getLeaveReportId : (state) => {
        return state.leaveReportId
    },
    getLeaveReportName: (state) => {
        return state.leaveReportName
    },
    getExportLeaveReportDetails: (state) => {
        return state.exportLeaveReportDetails
    }
}

const actions = {
    setLeaveAllocations : ({commit}, data) => {
        commit('SET_LEAVE_ALLOCATIONS', data);
    },
    setLeaveAllocationByUserId : ({commit},data) => {
        commit('SET_LEAVE_ALLOCATIONS_BY_USER_ID',data)
    },
    setLeaveRequestByUserId : ({commit},data) => {
        commit("SET_LEAVE_REQUEST_BY_USER_ID",data)
    },
    setLeaveRequestById : ({commit},data) => {
        commit("SET_LEAVE_REQUEST_BY_ID",data)
    },
    setExportLeaveTypes : ({commit},data) => {
        commit("SET_EXPORT_LEAVE_TYPES",data)
    },
    setLeaveReport : ({commit}, data) => {
        commit('SET_LEAVE_REPORT', data);
    },
    setLeaveReportId: ({commit}, data) => {
        commit('SET_LEAVE_REPORT_ID', data);
    },
    setLeaveReportDetails : ({commit}, data) => {
        commit('SET_LEAVE_REPORT_DETAILS', data);
    },
    setLeaveReportName : ({commit}, data) => {
        commit('SET_LEAVE_REPORT_NAME', data);
    },
}

const mutations = {
    SET_LEAVE_ALLOCATIONS : (state,payload) => {
        let leave = payload.map(el => {
            let leave_types = el.leave_types
            let user_name = {userName:el.userName,id:el.user_id,f_name:el.f_name,l_name:el.l_name}
            let allocations = leave_types.map(type => {return {[`${type.leave_type}_allocation`]:type.allocation,
            [`${type.leave_type}_taken`]:type.leave_taken? type.leave_taken : 0,[`${type.leave_type}_balance`]: type.leave_balance ?? 0}})
            // console.log(allocations,'allocations');
            return Object.assign({},user_name,...allocations)
        }).flat();
        state.allLeaveAllocations = leave
    },
    SET_LEAVE_ALLOCATIONS_BY_USER_ID : (state,payload) => {
        if(payload.length){

        state.leaveAllocationByUserIdRaw = payload;

        let leave = payload.map(el => {
            let leave_types = el.leave_types
            let user_name = {userName:el.userName,id:el.user_id}
            let allocations = leave_types.map(type => {return {[`${type.leave_type}_allocation`]:type.allocation,
            [`${type.leave_type}_taken`]:type.leave_taken? type.leave_taken : 0,[`${type.leave_type}_balance`]: type.leave_balance ?? 0}})
            return Object.assign({},user_name,...allocations)
        }).flat();
        // console.log(leave,'allocation leave')
        state.leaveAllocationByUserId = leave;
        } else{
            state.leaveAllocationByUserIdRaw = payload;
            state.leaveAllocationByUserId = payload;
        }
    },

    SET_EXPORT_LEAVE_TYPES: (state,payload) => {
        let leave_types = payload.map(el => {
            return [`${el.name.toUpperCase()} ALLOCATION`,`${el.name.toUpperCase()} TAKEN`, `${el.name.toUpperCase()} BALANCE`];
        });
        let headers = ['SL NO','USER NAME', ...leave_types.flat(1)];
        // console.log(headers,'headers');
        state.exportLeaveAllocationsHeaders = headers;
    },

    SET_LEAVE_REQUEST_BY_USER_ID : (state,payload) => {
        state.leaveRequestByUserId = payload;
        state.leaveRequestEvents = payload.map(item => 
            {
                return {
                id:item.id,
                user_id: item.user_id,
                title:`${item.abbrv} - ${item.status}`,
                start:item.start_date,
                end:moment(item.end_date, "YYYY-MM-DD").add(1, 'd').format('YYYY-MM-DD'),
                color:item.color,
                classNames:(item.status == 'pending') ? 'eventPending' : ''}
            });
    },
    SET_LEAVE_REQUEST_BY_ID : (state,payload) => {
        let leave_request_by_id= payload.map(el=> {
            return {...el,start_date:moment(el.start_date).format('DD-MM-YYYY'),end_date:moment(el.end_date).format('DD-MM-YYYY')}
        });
        state.leaveRequestById = leave_request_by_id[0]
    },
    // SET_UNREAD_LEAVE_REQUESTS : (state,payload) => {
    //     state.unreadLeaveRequests = payload.map(el => {
    //         return {...el,request_time:moment.utc(el.createdAt).local().format("hh:mm a"),
    //         request_date:moment.utc(el.createdAt).local().format("MMMM DD, YYYY"),
    //         requested_ago:moment.utc(el.createdAt).local().fromNow()}
    //     })
        // state.unreadLeaveRequests = payload;
    // }
    SET_LEAVE_REPORT : (state,payload) => {
        let leave = payload.map(el => {
            let leave_types = el.leave_types
            let user_name = {userName:el.userName,id:el.user_id,f_name:el.f_name,l_name:el.l_name}
            let allocations = leave_types.map(type => {return {[`${type.leave_type}_allocation`]:type.allocation,
            [`${type.leave_type}_taken`]:type.leave_taken? type.leave_taken : 0,[`${type.leave_type}_balance`]: type.leave_balance ?? 0}})
            // console.log(allocations,'allocations');
            return Object.assign({},user_name,...allocations)
        }).flat();

        if(payload.length){
            // console.log(payload[0],'paylaod[0');
            function transformArray(objectsArray) {
                // Initialize an empty array to store the transformed data
                let transformedArray = [];
            
                // Iterate through each object in the array
                objectsArray.forEach((obj,idx) => {
                    // Extract relevant data from the object
                    const id = idx+1;
                    const userName = obj.userName;
                    let leave_type_array = [];
                    // Iterate through each leave type in the object
                    obj.leave_types.forEach(leaveType => {
                        // Extract leave type specific data
                        const allocation = leaveType.allocation;
                        const leaveTaken = leaveType.leave_taken;
                        const leaveBalance = leaveType.leave_balance
            
                        // Push an array containing the extracted data to the transformed array
                        leave_type_array.push([ allocation, leaveTaken, leaveBalance]);
                    });
                    transformedArray.push([id,userName,...leave_type_array.flat(1)])
                });
            
                return transformedArray;
            }
            
            // Example usage
            const sampleData = payload;
            
            const transformedData = transformArray(sampleData);
            state.exportLeaveReport = transformedData;
        }
        state.leaveReport = leave
    },
    SET_LEAVE_REPORT_ID : (state, payload) => {
        state.leaveReportId = payload;
    },

    SET_LEAVE_REPORT_NAME : (state, payload) => {
        state.leaveReportName = payload;
    },

    SET_LEAVE_REPORT_DETAILS: (state,payload) => {
        if(payload.length){
            // console.log(payload[0],'paylaod[0');
            function transformArray(objectsArray) {
                // Initialize an empty array to store the transformed data
                let transformedArray = [];
            
                // Iterate through each object in the array
                objectsArray.forEach((obj,idx) => {
                    // Extract relevant data from the object
                    const id = idx+1;
                    const leave_type = obj.leave_type_name
                    const userName = obj.user_name;
                    const from = obj.from;
                    const to = obj.to;
                    const half_day = obj.half_day
                    const session = obj.session
                    const no_of_days = obj.no_of_days
                    const status = obj.leave_status
                    transformedArray.push([id,leave_type,userName,from,to,half_day,session,no_of_days,status])
                });
            
                return transformedArray;
            }
            const sampleData = payload;
            
            const transformedData = transformArray(sampleData);
            state.exportLeaveReportDetails = transformedData;
        }
        state.leaveReportDetails = payload
    },

}

export default{
    state,
    getters,
    actions,
    mutations
}
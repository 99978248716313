<template>
  <footer class="footer_admin">
    <div class="container-fluid">
      <span>Evalogical</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-admin",
};
</script>

const state = {
    allLeaveTypes:[],
    leaveTypes: [],
    leaveTypeById: {},
}

const getters = {
    getAllLeaveTypes: (state) => {
        return state.allLeaveTypes;
    },
    getLeaveTypes: (state) => {
        return state.leaveTypes;
    },
    getLeaveTypeById: (state) => {
        return state.leaveTypeById;
    }
}

const actions = {

    getAllLeaveTypes: ({commit},data) => {
        commit('SetAllLeaveTypes',data);
    },
    getLeaveTypes: ({ commit }, data) => {
        commit("SetLeaveTypes", data);
    },

    getLeaveTypeById: ({ commit }, data) => {
        commit("SetLeaveTypeById", data);
    },

    createLeaveType: ({ commit }, data) => {
        commit("SetAllLeaveTypes", data);
    },
    deleteLeaveType: ({ commit }, data) => {
        commit("SetAllLeaveTypes", data);
    },
    updateLeaveType: ({ commit }, data) => {
        commit("SetAllLeaveTypes", data);
    }
}

const mutations = {

    SetAllLeaveTypes: (state,payload) => {
        state.allLeaveTypes = payload;
    },

    SetLeaveTypes: (state, payload) => {
        // console.log(payload, "payload");
        state.leaveTypes = payload;
    },

    SetLeaveTypeById: (state, payload) => {
        // console.log(payload, "payload");
        state.leaveTypeById = payload;
    }

}

export default {
    state,
    getters,
    actions,
    mutations,
};

import moment from "moment";

const state = {
   unreadNotifications:[],
}

const getters = {
    getUnreadNotifications : (state) => {
        return state.unreadNotifications;
    },
}

const actions = {
    setUnreadNotifications : ({commit}, data) => {
        commit('SET_UNREAD_NOTIFICATIONS', data);
    },
}

const mutations = {
   SET_UNREAD_NOTIFICATIONS: (state,payload) => {
    state.unreadNotifications = payload.map(el => {
        return {...el,request_time:moment.utc(el.created_at).local().format("hh:mm a"),
        request_date:moment.utc(el.created_at).local().format("MMMM DD, YYYY"),
        requested_ago:moment.utc(el.created_at).local().fromNow()}
    })
   }

}

export default{
    state,
    getters,
    actions,
    mutations
}
<template>
  <div class="form-floating" :class="[widthClass, { 'mb-3': shouldAddMargin }]">
    <input
      :type="type"
      class="form-control admin_input"
      :class="v.$error ? 'is-invalid' : classProps"
      v-model="input"
      :placeholder="placeholder"
      id="floatingInput"
      @input="$emit('input', $event.target.value)"
    />
    <label for="floatingInput">{{ label }}</label>

    <!-- <div class="invalid-feedback fw-bolder" v-if="v.$error">
      <span v-if="!v.required"> This field is required..! </span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    classProps: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      required: false,
      default: "text",
    },
    v: {
      type: Object,
      required: true,
    },
    shouldAddMargin: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    widthClass: {
      type: String,
      required: false,
      default: "100%",
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      },
    },
  },
};
</script>

<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>

<style lang="scss">
// Import Main styles for this application
// @import "styles/admin";
@import "assets/scss/admin";
</style>

<script>
import AuthServices from './services/AuthServices';
import store from './store';
// import * as microsoftTeams from '@microsoft/teams-js'

// import axios from "axios";
// import store from "./store";
export default {
  data() {
    return {
      // microsoftTeams,
    }
  },
  mounted() {
    this.getUnreadNotifications()
  },
  async created() {
    this.interval = setInterval(() => {
      if (store.getters.isLoggedIn) {
        this.getUnreadNotifications()
      }
    }, 10000)
    // if (store.getters.isLoggedIn) {
      // console.log(this.microsoftTeams, 'teams')
      // let pop = await this.$msal.authenticatePopup();
      // console.log(pop.account,'popres')
      // console.log(this.accounts[0],'accountsres')
      //   let token = await this.$msal.acquireTokenPopup({
      //     account: pop.account,
      //     scopes: ["User.Read"]
      //   })
      // this.microsoftTeams.app.initialize()
      // var authTokenRequest = {
      //   successCallback: function (result) { console.log("Success: " + result); },
      //   failureCallback: function (error) { console.log("Error getting token: " + error); }
      // };
      // let authenticate = this.microsoftTeams.authentication.authenticate()
      // console.log(authenticate,'authenicate')
      // this.microsoftTeams.authentication.getAuthToken(authTokenRequest)
      // this.$store.dispatch("setGraphApiToken", { token });
      console.log(window.navigator.userAgent,'userAgent')
    // }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    async getUnreadNotifications() {
      let data = {
        user_id: store.getters.isLoggedIn ? store.getters.getUserId : null,
        user_role_id: store.getters.isLoggedIn ? store.getters.currentUserRole : null,
      };
      const response = await AuthServices.getUnreadNotifications(data);

      // console.log(response,'notifications')
      if (response.status) {
        store.dispatch("setUnreadNotifications", response.data);
        // this.isRequestLoading = false;
      }
      const permissions = await AuthServices.getPermissions(data);
          if (permissions.status) {
            const AllPermissions = permissions.data
            this.$store.dispatch("permissions", { AllPermissions })
          }
    }
  },
  computed: {
    accounts() {
      return this.$msal.getAllAccounts()
    }
  }
  // created: function () {
  //   axios.interceptors.response.use(undefined, function (err) {
  //     // eslint-disable-next-line no-unused-vars
  //     return new Promise(function (resolve, reject) {
  //       if (err.config) {
  //         console.log(err);
  //         store.dispatch("logOut");
  //         this.$router.push("/");
  //       }
  //       // if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //       //   // if you ever get an unauthorized, logout the user
  //       //   this.$store.dispatch(AUTH_LOGOUT);
  //       //   // you can also redirect to /login if needed !
  //       // }
  //       throw err;
  //     });
  //   });
  // },
};
</script>

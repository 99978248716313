// CMS module

const state = {
  allServices: [],
  serviceById: {},

  allTestimonials: [],
  testimonialById: {},

  allPortfolios: [],
  portfolioById: {},

  allClients: [],
  clientById: {},

  allPortfolioCategories: [],
  categoryById: {},
};

const getters = {
  // loginDt: (state) => state.user,
  getAllServices: (state) => {
    return state.allServices;
  },
  getServiceById: (state) => {
    return state.serviceById;
  },

  getAllTestimonials: (state) => {
    return state.allTestimonials;
  },
  getTestimonialById: (state) => {
    return state.testimonialById;
  },

  getAllPortfolios: (state) => {
    return state.allPortfolios;
  },
  getPortfolioById: (state) => {
    return state.portfolioById;
  },

  getAllClients: (state) => {
    return state.allClients;
  },
  getClientById: (state) => {
    return state.getClientById;
  },

  getAllPortfolioCategories: (state) => {
    return state.allPortfolioCategories;
  },
  getCategoryById: (state) => {
    return state.categoryById;
  },
};

const actions = {
  getServices: ({ commit }, data) => {
    commit("SetAllServices", data);
  },

  addNewService: ({ commit }, data) => {
    commit("SetAllServices", data);
  },

  arrangeServices:({commit},data) => {
    commit('rearrangeServices',data);
  },

  deleteService: ({ commit }, data) => {
    commit("SetAllServices", data);
  },
  getServiceById: ({ commit }, data) => {
    commit("SetServiceById", data);
  },

  // testmony
  getTestimonials: ({ commit }, data) => {
    commit("SetAllTestimonials", data);
  },

  addNewTestimonial: ({ commit }, data) => {
    commit("SetAllTestimonials", data);
  },

  deleteTestimonial: ({ commit }, data) => {
    commit("SetAllTestimonials", data);
  },

  getTestimonialById: ({ commit }, data) => {
    commit("SetTestimonialById", data);
  },

  // portfolios
  getPortfolios: ({ commit }, data) => {
    commit("SetAllPortfolios", data);
  },

  addNewPortfolio: ({ commit }, data) => {
    commit("SetAllPortfolios", data);
  },

  deletePortfolio: ({ commit }, data) => {
    commit("SetAllPortfolios", data);
  },

  getPortfolioById: ({ commit }, data) => {
    commit("SetPortfolioById", data);
  },

  // portfolio-categories
  getAllPortfolioCategories: ({ commit }, data) => {
    commit("SetAllPortfolioCategories", data);
  },

  createPortfolioCategory: ({ commit }, data) => {
    commit("SetAllPortfolioCategories", data);
  },

  deleteCategory: ({ commit }, data) => {
    commit("SetAllPortfolioCategories", data);
  },

  getCategoryById: ({ commit }, data) => {
    commit("SetCategoryById", data);
  },

  // clients
  getClients: ({ commit }, data) => {
    commit("SetClients", data);
  },
  getClientById: ({ commit }, data) => {
    commit("SetClients", data);
  },
};

const mutations = {
  // login: (state, payload) => (state.user = payload.data),
  SetAllServices: (state, payload) => {
    // console.log(payload, "payload");
    state.allServices = payload;
  },

  rearrangeServices: (state,payload) => {
    // console.log(payload,'rearrange')
    state.allServices = payload.map((item,index) => {
     return  {...item,order:index + 1}
    })
  },

  SetServiceById: (state, payload) => {
    // console.log(payload, "payload");
    state.serviceById = payload;
  },

  SetAllTestimonials: (state, payload) => {
    // console.log(payload, "payload");
    state.allTestimonials = payload;
  },

  SetTestimonialById: (state, payload) => {
    // console.log(payload, "___payload");
    state.testimonialById = payload;
  },

  SetAllPortfolios: (state, payload) => {
    // console.log(payload, "payload");
    state.allPortfolios = payload;
  },

  SetPortfolioById: (state, payload) => {
    // console.log(payload, "___payload");
    state.portfolioById = payload;
  },

  SetAllPortfolioCategories: (state, payload) => {
    // console.log(payload, "__payload");
    state.allPortfolioCategories = payload;
  },

  SetCategoryById: (state, payload) => {
    // console.log(payload, "payload");
    state.categoryById = payload;
  },

  SetClients: (state, payload) => {
    // console.log(payload);
    state.allClients = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

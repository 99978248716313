export default {
  apiGateWay: {


    // local
    // CLIENT_ID: 8,
    // CLIENT_SECRET: "OtqHEzxPiZNB47nyuyZGzWyEgOMrcQXxCOkqQrbO",

    // URL: "http://localhost:8000/api/",
    // DOMAIN: 'http://localhost:8000',

    // // staging
    // CLIENT_ID: 6,
    // CLIENT_SECRET: "R00fmmW3BQUhJbCXSyv7U0drGXybc4z8kxFq4Lxp",

    // URL: "https://evalogical.com/api/",
    // DOMAIN: "https://evalogical.com",

    //live
    CLIENT_ID: 2,
    CLIENT_SECRET: "nI5X96z5aiJkL9QTKkwIFJYubNuK0Qo74nq1cfOH",
    URL: "https://evalogical.com/api/",
    DOMAIN: "https://evalogical.com",

  },
};
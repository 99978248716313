<template>
    <div role="button" class="pt-2 pb-3 px-3 notif_list_wrpr" @click="routeTo(link)">
        <div class="d-flex">
            <div class="pe-3">
                <b-avatar v-if="profilePic" size="2.5rem" variant="info" :src="profilePic" class="mr-3"></b-avatar>
                <AlphaAvatar v-else :firstName="f_name" :lastName="l_name" :size="40" />
            </div>
            <div class="notif_info_wrpr">
                <p class="fw-600">{{ f_name + " " + l_name }}</p>
                <p class="mb-2"><span class="me-1">📅</span>{{ text }}
                    <!-- <span class="ms-3 fw-600 notif_time">{{ requestedAgo }}</span> -->
                    </p>
                <p class=" mb-0 notif_info_footer">
                    <svg class="fa-clock me-1 clock_svg" aria-hidden="true" focusable="false" data-prefix="fas"
                        data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                        data-fa-i2svg="">
                        <path fill="currentColor"
                            d="M256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512zM232 256C232 264 236 271.5 242.7 275.1L338.7 339.1C349.7 347.3 364.6 344.3 371.1 333.3C379.3 322.3 376.3 307.4 365.3 300L280 243.2V120C280 106.7 269.3 96 255.1 96C242.7 96 231.1 106.7 231.1 120L232 256z">
                        </path>
                    </svg>
                    <span class="fw-bold requestAgo">{{ requestedAgo }} </span>
                    <!-- {{ requestDate }} -->
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import AlphaAvatar from './AlphaAvatar.vue';

export default {
    props: ["f_name","l_name","profilePic","text",'requestTime','requestDate','requestedAgo','link'],
    methods:{
        routeTo(route){
            this.$emit('route',route)
        },
    },
    components: { AlphaAvatar }
}
</script>

// Auth module
import router from "@/router";
import axios from "axios";

const state = {
    loggedIn:false,
    is_initial_login:false,
    user: {},
    user_id:null,
    permissions: {},
    reporting_people: [],
    currentUserRole: null,
    userRoleName:null,
    isAdmin:null,
    isModerator:null,
    hasAccess: null,
    sidebarState: false,
    cmsOpen:false,
    teamsOpen:false,
    leaveOpen:false,
    crmOpen:false,
    editPermissions: {},
    editAccess:false,
    deleteAccess:false,
    deleteAccessPermission:false,
    allRoutes:[],
    activeRoute:'dashboard',
    user_profile_pic:'',
    admin_id_user_role_id:1,
    hr_admin_user_role_id:2,
    director_user_role_id:3,
    employee_user_role_id:4,
    graph_api_token:'',
};

const getters = {
    // loginDt: (state) => state.user,
    isLoggedIn: state => {
        return state.loggedIn;
    },
    isInitialLogin: state => {
        return state.is_initial_login;
    },
    isAdmin: state => {
        return ((state.admin_id_user_role_id == state.currentUserRole)) ? true : false;
    },
    isModerator: state => {
        // console.log(state.isModerator,'isModerator')
        return state.isModerator;
    },
    hasAccess: state => {
        return (state.isAdmin || state.isModerator) ? true : false;
    },
    getUser: state => {
        return state.user;
    },
    getReportingPeople:  state => {
        return state.reporting_people;
    },
    getUserId: state => {
        return state.user[0].id;
    },
    getPermissions: state => {
        return state.permissions;
    },
    getEditPermissions: state => {
        return state.editPermissions;
    },
    currentUserRole: state => {
        return state.currentUserRole;
    },
    getUserRoleName: state => {
        return state.userRoleName;
    },
    isSidebarState: state => {
        return state.sidebarState;
    },
    isCmsOpen: state => {
        return state.cmsOpen;
    },
    isTeamsOpen: state => {
        return state.teamsOpen;
    },
    isLeaveOpen: state => {
        return state.leaveOpen;
    },
    isCrmOpen: state => {
        return state.crmOpen;
    },
    getActiveRoute: state => {
        return state.activeRoute;
    },
    getUserProfilePic: state => {
        return state.user_profile_pic;
    },
    getEditAccess: state => {
        return state.editAccess;
    },
    getDeleteAccess: state => {
        return state.deleteAccess;
    },
    getDeleteAccessPermission: state => {
        return state.deleteAccessPermission;
    },
    getHrAdminUserRoleId: state => {
        return state.hr_admin_user_role_id;
    },
    getDirectorUserRoleId: state => {
        return state.director_user_role_id;
    },
    getEmployeeUserRoleId: state => {
        return state.employee_user_role_id;
    },
    getGraphApiToken: state => {
        return state.graph_api_token
    }
};

const actions = {
    login: ({
        commit
    }, {
        token,
        user,
        user_role_name,
        reporting_people,
    }) => {
        const accesstoken = `Bearer ${accesstoken}`;
        const user_role = user[0].user_role;

        localStorage.setItem("token", token);
        localStorage.setItem('user_role', user_role);

        // console.log(user,'store,user')
        
        commit("SetUser", user);
        commit("SetCurrentUser", user_role);
        commit("SetUserRoleName",user_role_name[0].name);
        commit("SET_PROFILE_PIC",user)
        commit('SET_REPORTING_PEOPLE',reporting_people);
        commit("SET_INITIAL_LOGIN",user[0].is_login)

        // set auth header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    setInitialLogin:({commit},is_initial_login) => {
        commit("SET_INITIAL_LOGIN", is_initial_login)
    },
    setGraphApiToken: ({commit},{token}) => {
        if(token.accessToken){
            localStorage.setItem("GraphToken", token.accessToken);
            commit('SET_GRAPH_API_TOKEN',token.accessToken)
        }
    },
    permissions:({commit},{AllPermissions}) => {
        commit("setPermissions", AllPermissions)
    },
    setUserProfilePic:({commit},user) => {
        commit("SET_PROFILE_PIC", user)
    },

    editPermissions:({commit},{userPermissions}) => {
        commit('setEditPermissions', userPermissions)
    },

    setDeleteAccessPermission:({commit}, { deleteAccessPermission }) => {
        commit('SET_DELETE_ACCESS_PERMISSION',deleteAccessPermission)
    },

    setEditAccess:({commit},edit_access) => {
        commit('SET_EDIT_ACCESS',edit_access)
    },
    setDeleteAccess:({commit},delete_access) => {
        commit('SET_DELETE_ACCESS',delete_access)
    },

    logOut: ({commit}) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_role");
        commit("logOutUser");
        sessionStorage.clear();
        commit('SET_GRAPH_API_TOKEN','')
    },

    sidebarState: ({commit}, isOpen) => {
        // console.log(isOpen, "commit")
        commit("SetsidebarState", isOpen)
    },
    isCmsOpen: ({commit},isCmsOpen) => {
        commit("SET_CMS_OPEN", isCmsOpen)
    },
    isTeamsOpen: ({commit},isTeamsOpen) => {
        commit("SET_TEAMS_OPEN", isTeamsOpen)
    },
    isLeaveOpen: ({commit},isLeaveOpen) => {
        commit("SET_LEAVE_OPEN", isLeaveOpen)
    },
    isCrmOpen: ({commit},isCrmOpen) => {
        commit("SET_CRM_OPEN", isCrmOpen)
    },
    setActiveRoute: ({commit}, route) => {
        commit("SET_ACTIVE_ROUTE",route)
    }
};

const mutations = {
    // login: (state, payload) => (state.user = payload.data),
    SetUser: (state, payload) => {
        // console.log(payload, "payload");
        state.user = payload;
        
    },
    SET_GRAPH_API_TOKEN: (state, payload) => {
        state.graph_api_token = payload
    },
    SET_REPORTING_PEOPLE: (state, payload) => {
        state.reporting_people = payload;
    },
    SetCurrentUser: (state, payload) => {
        state.currentUserRole = payload;
        // console.log(state.user,'payload afads')
        state.isAdmin = (payload == 1) ? true : false;
    },
    SetUserRoleName: (state, payload) => {
        state.userRoleName = payload;
        // console.log(payload,'userrolename')
        state.isModerator = (payload == 'moderator') ? true : false;
    },
    setPermissions: (state, payload) => {
        state.permissions = payload;
        state.loggedIn = localStorage.getItem("token") ? true : false;
    },
    setEditPermissions: (state, payload) => {
        state.editPermissions = payload;
    },
    SET_DELETE_ACCESS_PERMISSION: (state, payload) => {
        state.deleteAccessPermission = payload;
    },
    SET_EDIT_ACCESS:  (state, payload) => {
        state.editAccess = payload;
    },
    SET_DELETE_ACCESS:  (state, payload) => {
        state.deleteAccess = payload;
    },
    SET_INITIAL_LOGIN: (state, payload) => {
        state.is_initial_login = payload;
    },
    logOutUser: (state) => {
        state.user = {};
        state.loggedIn = localStorage.getItem("token") ? true : false;
        state.userRoleName = null;
    },
    SetsidebarState: (state, payload) => {
        state.sidebarState = payload;
    },
    SET_CMS_OPEN: (state, payload) => {
        state.cmsOpen = payload;
    },
    SET_TEAMS_OPEN: (state, payload) => {
        state.teamsOpen = payload;
    },
    SET_LEAVE_OPEN: (state, payload) => {
        state.leaveOpen = payload;
    },
    SET_CRM_OPEN: (state, payload) => {
        state.crmOpen = payload;
    },
    SET_ACTIVE_ROUTE: (state,payload) => {
        state.activeRoute = payload;
    },
    SET_PROFILE_PIC: (state,payload) => {
        state.user_profile_pic = payload[0].profile_pic;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
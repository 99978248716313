<template>
  <div>
    <nav class="sidebar sidebar-offcanvas" :class="isOpen == true ? 'open' : ''" id="sidebar">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center ">
        <a class="navbar-brand brand-logo mr-5" @click="routeTo('dashboard')" >
          <img src="../assets/imgs/svg/logo.svg" alt="logo" class="mr-2" />
        </a>
        <a v-if="isOpen == false" class="navbar-brand  mr-5" @click="routeTo('dashboard')">
          <img src="../assets/imgs/svg/4_dots.svg" alt="logo" class="mr-2" />
        </a>
      </div>
      <ul class="nav" >
        <li class="nav-item right" data-infotip="Dashboard"  :class="{
          is_active: isActive('dashboard'),
          hideMenu: checkPermission('dashboard'),
          isOpen: isOpen
        }">
          <a  class="nav-link"  @click="routeTo('dashboard')">
            <span class="icon-StyleTwo-tone menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
            </span>
            <span class="menu-title">Dashboard</span>
          </a>
        </li>
        <li class="nav-item right" data-infotip="Teams"
          :class="{ hideMenu: checkPermission('teams', 1), is_active: isActive('teams', 1) || isOpenTeams,isOpen: isOpen }">
          <a ref="teamsButtonOne" class="nav-link" v-b-toggle.collapse-1 @click="updateTeamsOpen">
            <span class="icon-StyleTwo-tone-1 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">Teams</span>
            <i class="icon-arrow-right-2-svgrepo-com ms-auto"></i>
          </a>
        </li>

        <!-- Leave Routes -->
        <li class="nav-item right"  data-infotip="Leave"
          :class="{ hideMenu: checkPermission('leave', 1), is_active: isActive('leave', 1) || isOpenLeave,isOpen: isOpen }">
          <a ref="leaveButtonOne" class="nav-link leave_sidebar" v-b-toggle.collapse-5 @click="updateLeaveOpen">
            <span class="icon-StyleTwo-tone-5 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
            </span>
            <span class="menu-title">Leave</span>
            <i class="icon-arrow-right-2-svgrepo-com ms-auto"></i>
            <span v-if="hasNotifications" class="notif_badge_sidebar" :class="{ sidebarOpen: !isOpen }"></span>
          </a>
          <!-- <b-collapse id="collapse-5" v-model="isOpenLeave" accordion="sidebar_options"> -->
          <!-- <div> -->
          <!-- </b-collapse> -->
        </li>

        <!-- asset Management-->
        <li class="nav-item right bottom_hr mb-0" data-infotip="Assets" :class="{
          is_active: isActive('assets'),
          hideMenu: checkPermission('assets'),
          isOpen: isOpen
        }">
          <a class="nav-link" @click="routeTo('assets')">
            <span class="icon-wallet-svgrepo-com menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
            </span>
            <span class="menu-title">Assets</span>
          </a>
        </li>

        <li v-if="isOpen" class="nav-item right bottom_hr mb-0 pb-2" data-infotip="Assets" :class="{
          is_active: false,
          hideMenu: checkPermission('cms', 1) && checkPermission('blog')
            && checkPermission('careers', 1) && checkPermission('leads', 1) && checkPermission('estimate-list'),
          isOpen: isOpen
        }">
          <a class="sub_heading">
            <span class="menu-title">Utilities</span>
          </a>
        </li>

        <li class="nav-item right" data-infotip="CMS" :class="{
          hideMenu: checkPermission('cms', 1) && checkPermission('blog')
            && checkPermission('careers', 1) && checkPermission('leads', 1),
          is_active: isActive('cms', 1) || isCmsActive(),
          isOpen: isOpen
        }">
          <a ref="cmsButtonOne" class="nav-link" v-b-toggle.collapse-2 @click="updateCmsOpen">
            <span class="icon-StyleTwo-tone-109 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">CMS</span>

            <i class="icon-arrow-right-2-svgrepo-com ms-auto"></i>
          </a>
        </li>

        <li class="nav-item right" data-infotip="CRM" :class="{
          hideMenu: checkPermission('estimate-list') ,
          is_active: isActive('estimate-list') || isCrmActive(),
          isOpen: isOpen
        }">
          <a ref="crmButtonOne" class="nav-link" v-b-toggle.collapse-2 @click="updateCrmOpen">
            <span class="icon-StyleTwo-tone-3 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
              <span class="path7 activ_clr"></span>
              <span class="path8 activ_clr"></span>
            </span>
            <span class="menu-title">CRM</span>

            <i class="icon-arrow-right-2-svgrepo-com ms-auto"></i>
          </a>
        </li>

      </ul>
    </nav>
    <nav ref="secondSidebar" class="sidebar secondSidebar sidebar-offcanvas "
      :class="{ open: isCmsOpen, sidebarOpen: isOpen }" id="sidebar" v-closable="{
        exclude: ['cmsButtonOne'],
        handler: 'hideCmsSidebar',
      }">

      <ul class="nav">

        <li class="nav-item" :class="{
          is_active: isActive('blog'),
          hideMenu: checkPermission('blog')
        }">
          <a class="nav-link" @click="routeTo('blog')">
            <span class="icon-document-svgrepo-com menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
            </span>
            <span class="menu-title">Blog</span>
          </a>
        </li>
        <li class="nav-item" :class="{
          is_active: isActive('case-study'),
          hideMenu: checkPermission('case-study')
        }">
          <a class="nav-link" @click="routeTo('case-study')">
            <span class="icon-StyleTwo-tone-54 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
            </span>
            <span class="menu-title">Case Study</span>
          </a>
        </li>
        <li class="nav-item" :class="{
          is_active: isActive('services'),
          hideMenu: checkPermission('services')
        }">
          <a class="nav-link" @click="routeTo('services')">
            <span class="icon-StyleTwo-tone-23 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">Services</span>
          </a>
        </li>

        <li class="nav-item" :class="{
          is_active: isActive('testimonials'),
          hideMenu: checkPermission('testimonials')
        }">
          <a class="nav-link" @click="routeTo('testimonials')">
            <span class="icon-StyleTwo-tone-92 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
            </span>
            <span class="menu-title">Testimonials</span>
          </a>
        </li>

        <li class="nav-item" :class="{
          is_active: isActive('portfolio-categories'),
          hideMenu: checkPermission('portfolio-categories')
        }">
          <a class="nav-link" @click="routeTo('portfolio-categories')">
            <span class="icon-category-svgrepo-com menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">Portfolio Categories</span>
          </a>
        </li>

        <li class="nav-item" :class="{
          is_active: isActive('portfolios'),
          hideMenu: checkPermission('portfolios')
        }">
          <a class="nav-link" @click="routeTo('portfolios')">
            <span class="icon-StyleTwo-tone-44 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">Portfolios</span>
          </a>
        </li>

        <li class="nav-item" :class="{
          is_active: isActive('clients'),
          hideMenu: checkPermission('clients')
        }">
          <a class="nav-link" @click="routeTo('clients')">
            <span class="icon-StyleTwo-tone-3 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
              <span class="path7 activ_clr"></span>
              <span class="path8 activ_clr"></span>
            </span>
            <span class="menu-title">Clients</span>
          </a>
        </li>

        <li class="nav-item"
          :class="{ hideMenu: checkPermission('careers', 1), is_active: isActive('careers', 1) || isOpenCareer }">
          <a class="nav-link" v-b-toggle.collapse-3>
            <span class="icon-StyleTwo-tone-45 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            <span class="menu-title">Careers</span>

            <i class="icon-arrow-right-2-svgrepo-com ms-auto" :class="{ open: isOpenCareer }"></i>
          </a>
          <b-collapse id="collapse-3" v-model="isOpenCareer" accordion="sidebar_options">
            <div>
              <ul class="nav flex-column sub-menu">
                <li class="nav-item" :class="{
                  active: isActive('career-positions'),
                  hideMenu: checkPermission('career-positions')
                }">
                  <a class="nav-link" @click="routeTo('career-positions')">
                    <span class="icon-user-svgrepo-com menu-icon">
                      <span class="path1 activ_clr"></span>
                      <span class="path2 activ_clr"></span>
                      <span class="path3 activ_clr"></span>
                      <span class="path4 activ_clr"></span>
                    </span>
                    Career Positions
                  </a>
                </li>

                <li class="nav-item" :class="{
                  active: isActive('career-openings'),
                  hideMenu: checkPermission('career-openings')
                }">
                  <a class="nav-link" @click="routeTo('career-openings')">
                    <span class="icon-activity-svgrepo-com menu-icon">
                      <span class="path1 activ_clr"></span>
                      <span class="path2 activ_clr"></span>
                      <span class="path3 activ_clr"></span>
                      <span class="path4 activ_clr"></span>
                    </span>
                    Career Openings
                  </a>
                </li>

                <li class="nav-item" :class="{
                  active: isActive('career-applications'),
                  hideMenu: checkPermission('career-applications')
                }">
                  <a class="nav-link" @click="routeTo('career-applications')">
                    <span class="icon-StyleTwo-tone-7 menu-icon">
                      <span class="path1 activ_clr"></span>
                      <span class="path2 activ_clr"></span>
                      <span class="path3 activ_clr"></span>
                      <span class="path4 activ_clr"></span>
                    </span>
                    Career Applications
                  </a>
                </li>
              </ul>
            </div>
          </b-collapse>
        </li>
      </ul>
      <span @click="hideCmsSidebar" role="button" class="sidebar_close icon-x menu-icon p-2"
        :class="{ open: isCmsOpen }"></span>
    </nav>
    <nav ref="thirdSidebar" class="sidebar secondSidebar sidebar-offcanvas "
      :class="{ open: isTeamsOpen, sidebarOpen: isOpen }" id="sidebar" v-closable="{
        exclude: ['teamsButtonOne'],
        handler: 'hideTeamsSidebar',
      }">
      <ul class="nav">
        <li class="nav-item" :class="{
          active: isActive('users'),
          hideMenu: checkPermission('users')
        }">
          <a class="nav-link" @click="routeTo('users')">
            <span class="icon-user-svgrepo-com1 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Users
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('user-roles'),
          hideMenu: checkPermission('user-roles')
        }">
          <a class="nav-link" @click="routeTo('user-roles')">
            <span class="icon-user-svgrepo-com1 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
            </span>
            User Roles
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('designations'),
          hideMenu: checkPermission('designations')
        }">
          <a class="nav-link" @click="routeTo('designations')">
            <span class="icon-briefcase menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Designations
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('user-insights'),
          hideMenu: checkPermission('user-insights')
        }">
          <a class="nav-link" @click="routeTo('user-insights')">
            <span class="icon-pie-chart menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Insights
          </a>
        </li>
      </ul>
      <span @click="hideTeamsSidebar" role="button" class="sidebar_close icon-x menu-icon p-2"
        :class="{ open: isTeamsOpen }"></span>
    </nav>
    <nav ref="fourthSidebar" class="sidebar secondSidebar sidebar-offcanvas "
      :class="{ open: isLeaveOpen, sidebarOpen: isOpen }" id="sidebar" v-closable="{
        exclude: ['leaveButtonOne'],
        handler: 'hideLeaveSidebar',
      }">
      <ul class="nav">
        <li class="nav-item" :class="{
          active: isActive('holidays'),
          hideMenu: checkPermission('holidays')
        }">
          <a class="nav-link" @click="routeTo('holidays')">
            <span class="icon-sun menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Holidays
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('leave-types'),
          hideMenu: checkPermission('leave-types')
        }">
          <a class="nav-link" @click="routeTo('leave-types')">
            <span class="icon-package menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Leave Types
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('leave-allocation'),
          hideMenu: checkPermission('leave-allocation')
        }">
          <a class="nav-link" @click="routeTo('leave-allocation')">
            <span class="icon-git-merge menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
            </span>
            Leave Allocation
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('calendar'),
          hideMenu: checkPermission('calendar')
        }">
          <a class="nav-link" @click="routeTo('calendar')">
            <span class="icon-StyleTwo-tone-5 menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
            </span>
            Calendar
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('leave-request'),
          hideMenu: checkPermission('leave-request')
        }">
          <a class="nav-link leave_sidebar" @click="routeTo('leave-request')">
            <span class="icon-layers menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
            </span>
            Leave Request
            <span v-if="hasNotifications" class="notif_badge_sidebar" :class="{ sidebarOpen: !isOpen }"></span>
          </a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('leave-reports'),
          hideMenu: checkPermission('leave-reports')
        }">
          <a class="nav-link leave_sidebar" @click="routeTo('leave-reports')">
            <span class="icon-file-text menu-icon">
              <span class="path1 activ_clr"></span>
              <span class="path2 activ_clr"></span>
              <span class="path3 activ_clr"></span>
              <span class="path4 activ_clr"></span>
              <span class="path5 activ_clr"></span>
              <span class="path6 activ_clr"></span>
            </span>
            Reports
          </a>
        </li>
      </ul>
      <span @click="hideLeaveSidebar" role="button" class="sidebar_close icon-x menu-icon p-2"
        :class="{ open: isLeaveOpen }"></span>
    </nav>
    <nav ref="fifthSidebar" class="sidebar secondSidebar sidebar-offcanvas "
      :class="{ open: isCrmOpen, sidebarOpen: isOpen }" id="sidebar" v-closable="{
        exclude: ['crmButtonOne'],
        handler: 'hideCrmSidebar',
      }">
      <ul class="nav">
        <li class="nav-item" :class="{
                active: isActive('estimate-list'),
                hideMenu: checkPermission('estimate-list')
              }">
            <a class="nav-link" @click="routeTo('estimate-list')">
                  <span class="icon-StyleTwo-tone-85 menu-icon">
                    <span class="path1 activ_clr"></span>
                    <span class="path2 activ_clr"></span>
                    <span class="path3 activ_clr"></span>
                    <span class="path4 activ_clr"></span>
                  </span>
                  Contact Us
              </a>
          </li>
      </ul>
      <span @click="hideCrmSidebar" role="button" class="sidebar_close icon-x menu-icon p-2"
        :class="{ open: isCrmOpen }"></span>
    </nav>
  </div>
</template>

<script>
import router from '@/router';
import store from '@/store';

export default {
  name: "SideBar",
  props: ["isOpen", "isCmsOpen", "isTeamsOpen",'isLeaveOpen','isCrmOpen'],
  data() {
    return {
      // activeItem: "dashboard",
      url_data: null,
      user_role: localStorage.getItem("user_role"),
      isOpenLeads: false,
      isOpenCareer: false,
      // isOpenTeams: false,
      // isOpenLeave: false,
      isOpenAssets: false,
      // isOpenCms: false,
    };
  },
  mounted() {
    this.url_data = this.$route.name;
    store.dispatch('setActiveRoute', this.$route.name)

  },
  methods: {
    routeTo(route) {
      router.push({ name: route })
      store.dispatch('setActiveRoute', route)
      this.closeAll();
    },
    closeAll(){
    this.hideCmsSidebar()
    this.hideTeamsSidebar()
    this.hideLeaveSidebar()
    this.hideCrmSidebar()
    },
    isActive(menuItem, main) {
      if (main) {
        let menus = router.options.routes[1].children
        let mainMenu = menus.filter(el => (el.name == menuItem))
        let subMenus = mainMenu[0].children.map(el => el.name)
        return subMenus.some(item => item === this.$route.name)
      }
      return this.activeItem === menuItem;
    },

    checkPermission(menu, main) {
      if (main) {
        let menus = router.options.routes[1].children
        let mainMenu = menus.filter(el => (el.name == menu))
        let subMenus = mainMenu[0].children.map(el => el.name)
        let permissions = this.permissions.map(item => item.menu);
        return subMenus.some(item => permissions.includes(item)) ? 0 : 1
      }
      let menus = this.permissions.filter(item => (item.menu == menu));
      if (menus.length) {
        return 0
      }
      return 1
    },
    updateCmsOpen() {
      this.$store.dispatch("isCmsOpen", !this.isOpenCMS);
    },
    updateTeamsOpen() {
      this.$store.dispatch("isTeamsOpen", !this.isOpenTeams);
    },
    updateLeaveOpen() {
      this.$store.dispatch("isLeaveOpen", !this.isOpenLeave);
    },
    updateCrmOpen(){
      this.$store.dispatch('isCrmOpen', !this.isOpenCRM)  
    },
    isCmsActive() {
      if (this.isOpenCMS  || this.isActive('blog') || this.isActive('case-study') || this.isActive('careers', 1)) {
        return true
      } else return false
    },
    isCrmActive(){
      if(this.isOpenCRM || this.isActive('estimate-list')){
        return true
      }else return false
    },
    isTeamsActive() {
      if (this.isOpenTeams) {
        return true
      } else return false
    },
    isLeaveActive() {
      if (this.isOpenLeave) {
        return true
      } else return false
    },
    hideCmsSidebar() {
      this.$store.dispatch("isCmsOpen", false);
    },
    hideTeamsSidebar() {
      this.$store.dispatch("isTeamsOpen", false);
    },
    hideLeaveSidebar() {
      this.$store.dispatch("isLeaveOpen", false);
    },
    hideCrmSidebar(){
      this.$store.dispatch("isCrmOpen", false)
    }
  },

  computed: {
    permissions() {
      return Array.from(this.$store.getters.getPermissions)
    },
    isOpens: function (el) {
      // console.log(el);
      const a = el;
      return a;
    },
    hasNotifications() {
      return store.getters.getUnreadNotifications.length > 0 ? true : false;
    },
    activeItem() {
      return store.getters.getActiveRoute;
    },
    isOpenCMS() {
      return this.$store.getters.isCmsOpen;
    },
    isOpenTeams() {
      return this.$store.getters.isTeamsOpen
    },
    isOpenLeave() {
      return this.$store.getters.isLeaveOpen
    },
    isOpenCRM() {
      return this.$store.getters.isCrmOpen
    }
  },
};
</script>

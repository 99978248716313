// Users module
// import axios from "axios";

const state = {
  allUsers: [],
  userbyId: {},

  reportingPersons:[],
  userTypeLevels:[],

  allUserRoles: [],
  userRoleById: {},

  allDesignations: [],
  designationById: {},

  additionalInfo:[],
  userViewId:'',
  editPrmsnId:'',

  loopValidations:{
    emergency:false,
    dependent:false,
    employer:false,
    education:false,
    salaries:false,
    banking:false
  },
};

const getters = {
  getUsers: (state) => {
    return state.allUsers;
  },
  getUserbyId: (state) => {
    return state.userbyId;
  },
  getAdditionalInfo: (state) => {
    return state.additionalInfo;
  },
  getUserViewId: (state) => {
    return state.userViewId;
  },
  getUserRoles: (state) => {
    return state.allUserRoles;
  },
  getReportingPersons: (state) => {
    return state.reportingPersons;
  },
  getUserTypeLevels: (state) => {
    return state.userTypeLevels;
  },
  getUserRoleById: (state) => {
    return state.userRoleById;
  },
  getDesignations: (state) => {
    return state.allDesignations;
  },
  getDesignationById: (state) => {
    return state.designationById;
  },
  getEditPrmsnId: (state) => {
    return state.editPrmsnId;
  },
  getLoopValidations: (state) => {
    return state.loopValidations;
  },
};

const actions = {
  getUsers: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  createUser: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  deleteUser: ({ commit }, users) => {
    commit("SetUsers", users);
  },
  getUserbyId: ({ commit }, user) => {
    commit("SetUserbyId", user);
  },
  setAdditionalInfo: ({ commit }, additional_info) => {
    commit("SET_ADDITIONAL_INFO", additional_info);
  },
  setEmergencyInfo: ({ commit },emergency_info) => {
    commit("SET_EMERGENCY_INFO",emergency_info)
  },
  setIdProofInfo:  ({ commit },idProofInfo) => {
    commit("SET_ID_PROOF_INFO",idProofInfo)
  },
  setMainInfo: ({ commit },mainInfo) => {
    commit("SET_MAIN_INFO",mainInfo)
  },
  setSalariesHistory: ({ commit },salaries_history) => {
    commit("SET_SALARIES_HISTORY",salaries_history)
  },
  setBankingDetails: ({ commit },banking_detail) => {
    commit("SET_BANKING_DETAIL",banking_detail)
  },
  setDependentInfo: ({ commit },dependent_info) => {
    commit("SET_DEPENDENT_INFO",dependent_info)
  },
  setEducationInfo: ({ commit },education_info) => {
    commit("SET_EDUCATION_INFO",education_info)
  },
  setPreviousEmployer: ({ commit },previous_employer) => {
    commit("SET_PREVIOUS_EMPLOYER",previous_employer)
  },
  setUserViewId: ({ commit }, id) => {
    commit("SET_USER_VIEW_ID", id);
  },
  editUser: ({ commit }, user) => {
    commit("SetUserbyId", user);
  },
  setUserTypeLevels:({ commit },data) => {
    commit("SET_USER_TYPE_LEVELS",data)
  },
  setReportingPersons: ({ commit },data) => {
    commit("SetReportingPersons",data)
  },
  getUserRoles: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  getUserRoleById: ({ commit }, data) => {
    commit("SetUserRoleById", data);
  },
  createUserRoles: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  deleteUserRole: ({ commit }, data) => {
    commit("SetAllUserRoles", data);
  },
  getDesignations: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
  getDesignationById: ({ commit }, data) => {
    commit("SetDesignationById", data);
  },
  createDesignations: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
  deleteDesignation: ({ commit }, data) => {
    commit("SetAllDesignations", data);
  },
  setEditPrmsnId: ({ commit }, id) => {
    commit("SET_EDIT_PRMSN_ID", id);
  },
  resetLoopValidations: ({ commit }, value) => {
    commit("RESET_LOOP_VALIDATIONS", value);
  },
  setEmergencyValidation: ({ commit }, value) => {
    commit("SET_EMERGENCY_VALIDATION", value);
  },
  setDependentValidation: ({ commit }, value) => {
    commit("SET_DEPENDENT_VALIDATION", value);
  },
  setEmployerValidation: ({ commit }, value) => {
    commit("SET_EMPLOYER_VALIDATION", value);
  },
  setEducationValidation: ({ commit }, value) => {
    commit("SET_EDUCATION_VALIDATION", value);
  },
  setSalariesValidation: ({ commit }, value) => {
    commit("SET_SALARIES_VALIDATION", value);
  },
  setBankingValidation: ({ commit }, value) => {
    commit("SET_BANKING_VALIDATION", value);
  },
};

const mutations = {
  // login: (state, payload) => (state.user = payload.data),
  SetUsers: (state, payload) => {
    state.allUsers = payload;
  },

  SetUserbyId: (state, payload) => {
    state.userbyId = payload;
  },

  SET_ADDITIONAL_INFO : (state,payload) => {
    state.additionalInfo = payload
  },

  SET_EMERGENCY_INFO : (state,payload) => {
    state.additionalInfo.emergency_infos = payload
  },
  SET_ID_PROOF_INFO : (state,payload) => {
    state.additionalInfo.id_proofs = payload
  },
  SET_SALARIES_HISTORY : (state,payload) => {
    state.additionalInfo.salaries_history = payload
  },
  SET_BANKING_DETAIL : (state,payload) => {
    state.additionalInfo.banking_details = payload
  },
  SET_DEPENDENT_INFO : (state,payload) => {
    state.additionalInfo.dependent_infos = payload
  },
  SET_EDUCATION_INFO : (state,payload) => {
    state.additionalInfo.education = payload
  },
  SET_PREVIOUS_EMPLOYER : (state,payload) => {
    state.additionalInfo.previous_employer = payload
  },
  SET_MAIN_INFO : (state,payload) => {
    state.userbyId = payload
  },

  SET_USER_VIEW_ID : (state,payload) => {
    state.userViewId = payload;
  },

  SetReportingPersons: (state,payload) => {
    state.reportingPersons = payload;
  },

  SET_USER_TYPE_LEVELS: (state,payload) => {
    state.userTypeLevels = payload.userTypeLevels;
  },

  SetAllUserRoles: (state, payload) => {
    state.allUserRoles = payload;
  },

  SetUserRoleById: (state, payload) => {
    state.userRoleById = payload;
  },

  SetAllDesignations: (state, payload) => {
    state.allDesignations = payload;
  },

  SetDesignationById: (state, payload) => {
    state.designationById = payload;
  },
  SET_EDIT_PRMSN_ID: (state, payload) => {
    state.editPrmsnId = payload;
  },
  RESET_LOOP_VALIDATIONS: (state, payload) => {
    state.loopValidations = payload;
  },
  SET_EMERGENCY_VALIDATION: (state, payload) => {
    state.loopValidations.emergency = payload;
  },
  SET_DEPENDENT_VALIDATION: (state, payload) => {
    state.loopValidations.dependent = payload;
  },
  SET_EMPLOYER_VALIDATION: (state, payload) => {
    state.loopValidations.employer = payload;
  },
  SET_EDUCATION_VALIDATION: (state, payload) => {
    state.loopValidations.education = payload;
  },
  SET_SALARIES_VALIDATION: (state, payload) => {
    state.loopValidations.salaries = payload;
  },
  SET_BANKING_VALIDATION: (state, payload) => {
    state.loopValidations.banking = payload;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
